.ant-layout-sider {
  flex: 0 0 70px !important;
  max-width: 70px !important;
  min-width: 70px !important;
  width: 70px !important;
}

.ant-layout-sider-children {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.sidebar-tab {
  color: #fff;
  font-size: 13px;
  font-weight: 400;
  line-height: 12px;
}

.rocket-sidebar {
  border: 5px solid #8e99ac;
}

.sidebar-scroll::-webkit-scrollbar {
  /* width: 3px;  */
}

.sidebar-scroll::-webkit-scrollbar-thumb {
  /* background-color: #555; 
  border-radius: 4px;  */
}

.sidebar-scroll::-webkit-scrollbar-track {
  /* background-color: #eee;
  border-radius: 4px;  */
}

.sidebar-scroll {
  transform: translateZ(0);
}
.sidebar-scroll{
  scroll-behavior: smooth;
  overflow: auto;

  /* scrollbar-width: thin; */
  /* scrollbar-color: #e0e0e0 #eee; */
}
.siderbar-tab-container-active {
  background-color: #21324e;
  color: #fff;
}
.app-layout {
  overflow: auto;
}

.chat-collapsed {
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.07),
    0px 1px 2px -2px rgba(0, 0, 0, 0.06), 0px 0px 1px 1px rgba(0, 0, 0, 0.05);
}

.transition {
  transition: all 4s ease-in-out;
}

.ant-modal-content{
  padding: 0px !important;
}

.modal-for-padding .ant-modal-content {
 padding: 20px !important;
}

.modal-for-padding .ant-modal-confirm-body-wrapper{
  padding: 20px !important;
}
