@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;0,1000;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900;1,1000&display=swap");

*, ::before, ::after{
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x:  ;
  --tw-pan-y:  ;
  --tw-pinch-zoom:  ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position:  ;
  --tw-gradient-via-position:  ;
  --tw-gradient-to-position:  ;
  --tw-ordinal:  ;
  --tw-slashed-zero:  ;
  --tw-numeric-figure:  ;
  --tw-numeric-spacing:  ;
  --tw-numeric-fraction:  ;
  --tw-ring-inset:  ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur:  ;
  --tw-brightness:  ;
  --tw-contrast:  ;
  --tw-grayscale:  ;
  --tw-hue-rotate:  ;
  --tw-invert:  ;
  --tw-saturate:  ;
  --tw-sepia:  ;
  --tw-drop-shadow:  ;
  --tw-backdrop-blur:  ;
  --tw-backdrop-brightness:  ;
  --tw-backdrop-contrast:  ;
  --tw-backdrop-grayscale:  ;
  --tw-backdrop-hue-rotate:  ;
  --tw-backdrop-invert:  ;
  --tw-backdrop-opacity:  ;
  --tw-backdrop-saturate:  ;
  --tw-backdrop-sepia:  ;
}

::backdrop{
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x:  ;
  --tw-pan-y:  ;
  --tw-pinch-zoom:  ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position:  ;
  --tw-gradient-via-position:  ;
  --tw-gradient-to-position:  ;
  --tw-ordinal:  ;
  --tw-slashed-zero:  ;
  --tw-numeric-figure:  ;
  --tw-numeric-spacing:  ;
  --tw-numeric-fraction:  ;
  --tw-ring-inset:  ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur:  ;
  --tw-brightness:  ;
  --tw-contrast:  ;
  --tw-grayscale:  ;
  --tw-hue-rotate:  ;
  --tw-invert:  ;
  --tw-saturate:  ;
  --tw-sepia:  ;
  --tw-drop-shadow:  ;
  --tw-backdrop-blur:  ;
  --tw-backdrop-brightness:  ;
  --tw-backdrop-contrast:  ;
  --tw-backdrop-grayscale:  ;
  --tw-backdrop-hue-rotate:  ;
  --tw-backdrop-invert:  ;
  --tw-backdrop-opacity:  ;
  --tw-backdrop-saturate:  ;
  --tw-backdrop-sepia:  ;
}

.container{
  width: 100%;
}

@media (min-width: 640px){
  .container{
    max-width: 640px;
  }
}

@media (min-width: 768px){
  .container{
    max-width: 768px;
  }
}

@media (min-width: 1024px){
  .container{
    max-width: 1024px;
  }
}

@media (min-width: 1280px){
  .container{
    max-width: 1280px;
  }
}

@media (min-width: 1536px){
  .container{
    max-width: 1536px;
  }
}

.visible{
  visibility: visible;
}

.absolute{
  position: absolute;
}

.relative{
  position: relative;
}

.sticky{
  position: -webkit-sticky;
  position: sticky;
}

.bottom-0{
  bottom: 0px;
}

.bottom-20{
  bottom: 5rem;
}

.bottom-\[78px\]{
  bottom: 78px;
}

.left-0{
  left: 0px;
}

.left-2{
  left: 0.5rem;
}

.left-20{
  left: 5rem;
}

.left-7{
  left: 1.75rem;
}

.right-0{
  right: 0px;
}

.right-2{
  right: 0.5rem;
}

.right-20{
  right: 5rem;
}

.top-5{
  top: 1.25rem;
}

.top-7{
  top: 1.75rem;
}

.z-10{
  z-index: 10;
}

.z-20{
  z-index: 20;
}

.z-40{
  z-index: 40;
}

.z-\[100000000000000000\]{
  z-index: 100000000000000000;
}

.m-auto{
  margin: auto;
}

.mb-1{
  margin-bottom: 0.25rem;
}

.mb-12{
  margin-bottom: 3rem;
}

.mb-2{
  margin-bottom: 0.5rem;
}

.mb-3{
  margin-bottom: 0.75rem;
}

.mb-4{
  margin-bottom: 1rem;
}

.mb-5{
  margin-bottom: 1.25rem;
}

.mb-6{
  margin-bottom: 1.5rem;
}

.mr-2{
  margin-right: 0.5rem;
}

.mr-3{
  margin-right: 0.75rem;
}

.mt-10{
  margin-top: 2.5rem;
}

.mt-3{
  margin-top: 0.75rem;
}

.mt-4{
  margin-top: 1rem;
}

.mt-5{
  margin-top: 1.25rem;
}

.mt-6{
  margin-top: 1.5rem;
}

.mt-7{
  margin-top: 1.75rem;
}

.mt-8{
  margin-top: 2rem;
}

.block{
  display: block;
}

.flex{
  display: flex;
}

.hidden{
  display: none;
}

.h-10{
  height: 2.5rem;
}

.h-11{
  height: 2.75rem;
}

.h-12{
  height: 3rem;
}

.h-14{
  height: 3.5rem;
}

.h-6{
  height: 1.5rem;
}

.h-7{
  height: 1.75rem;
}

.h-8{
  height: 2rem;
}

.h-\[100\%\]{
  height: 100%;
}

.h-\[16px\]{
  height: 16px;
}

.h-\[20px\]{
  height: 20px;
}

.h-\[21px\]{
  height: 21px;
}

.h-\[30px\]{
  height: 30px;
}

.h-\[350px\]{
  height: 350px;
}

.h-\[40\%\]{
  height: 40%;
}

.h-\[400px\]{
  height: 400px;
}

.h-\[43px\]{
  height: 43px;
}

.h-\[50px\]{
  height: 50px;
}

.h-\[58\%\]{
  height: 58%;
}

.h-\[650px\]{
  height: 650px;
}

.h-\[75vh\]{
  height: 75vh;
}

.h-\[88\%\]{
  height: 88%;
}

.h-\[95\%\]{
  height: 95%;
}

.h-full{
  height: 100%;
}

.w-14{
  width: 3.5rem;
}

.w-36{
  width: 9rem;
}

.w-48{
  width: 12rem;
}

.w-6{
  width: 1.5rem;
}

.w-7{
  width: 1.75rem;
}

.w-8{
  width: 2rem;
}

.w-96{
  width: 24rem;
}

.w-\[100\%\]{
  width: 100%;
}

.w-\[20\%\]{
  width: 20%;
}

.w-\[20px\]{
  width: 20px;
}

.w-\[21px\]{
  width: 21px;
}

.w-\[220px\]{
  width: 220px;
}

.w-\[23\%\]{
  width: 23%;
}

.w-\[250px\]{
  width: 250px;
}

.w-\[30\%\]{
  width: 30%;
}

.w-\[300px\]{
  width: 300px;
}

.w-\[30px\]{
  width: 30px;
}

.w-\[33\%\]{
  width: 33%;
}

.w-\[35\%\]{
  width: 35%;
}

.w-\[36\%\]{
  width: 36%;
}

.w-\[42\%\]{
  width: 42%;
}

.w-\[43px\]{
  width: 43px;
}

.w-\[45\%\]{
  width: 45%;
}

.w-\[46\%\]{
  width: 46%;
}

.w-\[48\%\]{
  width: 48%;
}

.w-\[49\%\]{
  width: 49%;
}

.w-\[49\.9\%\]{
  width: 49.9%;
}

.w-\[50\%\]{
  width: 50%;
}

.w-\[50px\]{
  width: 50px;
}

.w-\[60\%\]{
  width: 60%;
}

.w-\[64\%\]{
  width: 64%;
}

.w-\[70\%\]{
  width: 70%;
}

.w-\[80\%\]{
  width: 80%;
}

.w-\[86\%\]{
  width: 86%;
}

.w-full{
  width: 100%;
}

.flex-1{
  flex: 1 1 0%;
}

.transform{
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.cursor-not-allowed{
  cursor: not-allowed;
}

.cursor-pointer{
  cursor: pointer;
}

.resize{
  resize: both;
}

.flex-col{
  flex-direction: column;
}

.flex-wrap{
  flex-wrap: wrap;
}

.items-start{
  align-items: flex-start;
}

.items-end{
  align-items: flex-end;
}

.items-center{
  align-items: center;
}

.justify-start{
  justify-content: flex-start;
}

.justify-end{
  justify-content: flex-end;
}

.justify-center{
  justify-content: center;
}

.justify-between{
  justify-content: space-between;
}

.gap-1{
  gap: 0.25rem;
}

.gap-10{
  gap: 2.5rem;
}

.gap-2{
  gap: 0.5rem;
}

.gap-3{
  gap: 0.75rem;
}

.gap-4{
  gap: 1rem;
}

.gap-5{
  gap: 1.25rem;
}

.gap-6{
  gap: 1.5rem;
}

.gap-7{
  gap: 1.75rem;
}

.gap-8{
  gap: 2rem;
}

.overflow-auto{
  overflow: auto;
}

.overflow-hidden{
  overflow: hidden;
}

.overflow-y-auto{
  overflow-y: auto;
}

.overflow-x-hidden{
  overflow-x: hidden;
}

.overflow-y-scroll{
  overflow-y: scroll;
}

.rounded{
  border-radius: 0.25rem;
}

.rounded-2xl{
  border-radius: 1rem;
}

.rounded-3xl{
  border-radius: 1.5rem;
}

.rounded-full{
  border-radius: 9999px;
}

.rounded-lg{
  border-radius: 0.5rem;
}

.rounded-md{
  border-radius: 0.375rem;
}

.rounded-sm{
  border-radius: 0.125rem;
}

.rounded-bl-3xl{
  border-bottom-left-radius: 1.5rem;
}

.rounded-br-3xl{
  border-bottom-right-radius: 1.5rem;
}

.rounded-tl-3xl{
  border-top-left-radius: 1.5rem;
}

.rounded-tr-3xl{
  border-top-right-radius: 1.5rem;
}

.border{
  border-width: 1px;
}

.border-0{
  border-width: 0px;
}

.border-none{
  border-style: none;
}

.bg-\[\#0766FF\]{
  --tw-bg-opacity: 1;
  background-color: rgb(7 102 255 / var(--tw-bg-opacity));
}

.bg-\[\#17494D\]{
  --tw-bg-opacity: 1;
  background-color: rgb(23 73 77 / var(--tw-bg-opacity));
}

.bg-\[\#179763\]{
  --tw-bg-opacity: 1;
  background-color: rgb(23 151 99 / var(--tw-bg-opacity));
}

.bg-\[\#19334E\]{
  --tw-bg-opacity: 1;
  background-color: rgb(25 51 78 / var(--tw-bg-opacity));
}

.bg-\[\#21324E\]{
  --tw-bg-opacity: 1;
  background-color: rgb(33 50 78 / var(--tw-bg-opacity));
}

.bg-\[\#3d8dae\]{
  --tw-bg-opacity: 1;
  background-color: rgb(61 141 174 / var(--tw-bg-opacity));
}

.bg-\[\#509AF1\]{
  --tw-bg-opacity: 1;
  background-color: rgb(80 154 241 / var(--tw-bg-opacity));
}

.bg-\[\#587052\]{
  --tw-bg-opacity: 1;
  background-color: rgb(88 112 82 / var(--tw-bg-opacity));
}

.bg-\[\#58cb6a\]{
  --tw-bg-opacity: 1;
  background-color: rgb(88 203 106 / var(--tw-bg-opacity));
}

.bg-\[\#6FBAC2\]{
  --tw-bg-opacity: 1;
  background-color: rgb(111 186 194 / var(--tw-bg-opacity));
}

.bg-\[\#AE3D73\]{
  --tw-bg-opacity: 1;
  background-color: rgb(174 61 115 / var(--tw-bg-opacity));
}

.bg-\[\#C6DFFF66\]{
  background-color: #C6DFFF66;
}

.bg-\[\#E1EEFF0D\]{
  background-color: #E1EEFF0D;
}

.bg-\[\#E71333\]{
  --tw-bg-opacity: 1;
  background-color: rgb(231 19 51 / var(--tw-bg-opacity));
}

.bg-\[\#E8E8E8\]{
  --tw-bg-opacity: 1;
  background-color: rgb(232 232 232 / var(--tw-bg-opacity));
}

.bg-\[\#E8F2FF\]{
  --tw-bg-opacity: 1;
  background-color: rgb(232 242 255 / var(--tw-bg-opacity));
}

.bg-\[\#F3F3F3\]{
  --tw-bg-opacity: 1;
  background-color: rgb(243 243 243 / var(--tw-bg-opacity));
}

.bg-\[\#F5F7F9\]{
  --tw-bg-opacity: 1;
  background-color: rgb(245 247 249 / var(--tw-bg-opacity));
}

.bg-\[\#e8e8e8\]{
  --tw-bg-opacity: 1;
  background-color: rgb(232 232 232 / var(--tw-bg-opacity));
}

.bg-\[\#f5f7f9\]{
  --tw-bg-opacity: 1;
  background-color: rgb(245 247 249 / var(--tw-bg-opacity));
}

.bg-\[\#fff\]{
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.bg-slate-100{
  --tw-bg-opacity: 1;
  background-color: rgb(241 245 249 / var(--tw-bg-opacity));
}

.bg-slate-400{
  --tw-bg-opacity: 1;
  background-color: rgb(148 163 184 / var(--tw-bg-opacity));
}

.bg-white{
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.bg-none{
  background-image: none;
}

.p-1{
  padding: 0.25rem;
}

.p-10{
  padding: 2.5rem;
}

.p-2{
  padding: 0.5rem;
}

.p-3{
  padding: 0.75rem;
}

.p-4{
  padding: 1rem;
}

.p-5{
  padding: 1.25rem;
}

.p-6{
  padding: 1.5rem;
}

.p-7{
  padding: 1.75rem;
}

.p-\[15px\]{
  padding: 15px;
}

.p-\[19px\]{
  padding: 19px;
}

.p-\[20px\]{
  padding: 20px;
}

.p-\[24px\]{
  padding: 24px;
}

.p-\[2px\]{
  padding: 2px;
}

.p-\[8px\]{
  padding: 8px;
}

.px-3{
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

.px-5{
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.px-6{
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.px-\[10px\]{
  padding-left: 10px;
  padding-right: 10px;
}

.px-\[14px\]{
  padding-left: 14px;
  padding-right: 14px;
}

.px-\[16px\]{
  padding-left: 16px;
  padding-right: 16px;
}

.px-\[17px\]{
  padding-left: 17px;
  padding-right: 17px;
}

.px-\[1px\]{
  padding-left: 1px;
  padding-right: 1px;
}

.px-\[20px\]{
  padding-left: 20px;
  padding-right: 20px;
}

.px-\[23px\]{
  padding-left: 23px;
  padding-right: 23px;
}

.px-\[30px\]{
  padding-left: 30px;
  padding-right: 30px;
}

.px-\[3px\]{
  padding-left: 3px;
  padding-right: 3px;
}

.px-\[40px\]{
  padding-left: 40px;
  padding-right: 40px;
}

.py-2{
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.py-3{
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.py-4{
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.py-\[0px\]{
  padding-top: 0px;
  padding-bottom: 0px;
}

.py-\[17px\]{
  padding-top: 17px;
  padding-bottom: 17px;
}

.py-\[20px\]{
  padding-top: 20px;
  padding-bottom: 20px;
}

.py-\[24px\]{
  padding-top: 24px;
  padding-bottom: 24px;
}

.py-\[27px\]{
  padding-top: 27px;
  padding-bottom: 27px;
}

.py-\[29px\]{
  padding-top: 29px;
  padding-bottom: 29px;
}

.py-\[30px\]{
  padding-top: 30px;
  padding-bottom: 30px;
}

.py-\[4px\]{
  padding-top: 4px;
  padding-bottom: 4px;
}

.py-\[8px\]{
  padding-top: 8px;
  padding-bottom: 8px;
}

.pb-10{
  padding-bottom: 2.5rem;
}

.pb-3{
  padding-bottom: 0.75rem;
}

.pl-7{
  padding-left: 1.75rem;
}

.text-left{
  text-align: left;
}

.text-center{
  text-align: center;
}

.text-right{
  text-align: right;
}

.align-top{
  vertical-align: top;
}

.text-2xl{
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-3xl{
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.text-\[12px\]{
  font-size: 12px;
}

.text-\[14px\]{
  font-size: 14px;
}

.text-\[29px\]{
  font-size: 29px;
}

.text-base{
  font-size: 1rem;
  line-height: 1.5rem;
}

.text-lg{
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-sm{
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.text-xl{
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.text-xs{
  font-size: 0.75rem;
  line-height: 1rem;
}

.font-bold{
  font-weight: 700;
}

.font-extrabold{
  font-weight: 800;
}

.font-medium{
  font-weight: 500;
}

.font-normal{
  font-weight: 400;
}

.font-semibold{
  font-weight: 600;
}

.text-\[\#000\]{
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}

.text-\[\#01050F\]{
  --tw-text-opacity: 1;
  color: rgb(1 5 15 / var(--tw-text-opacity));
}

.text-\[\#0766FF\]{
  --tw-text-opacity: 1;
  color: rgb(7 102 255 / var(--tw-text-opacity));
}

.text-\[\#19334E\]{
  --tw-text-opacity: 1;
  color: rgb(25 51 78 / var(--tw-text-opacity));
}

.text-\[\#242424\]{
  --tw-text-opacity: 1;
  color: rgb(36 36 36 / var(--tw-text-opacity));
}

.text-\[\#58cb6a\]{
  --tw-text-opacity: 1;
  color: rgb(88 203 106 / var(--tw-text-opacity));
}

.text-\[\#616161\]{
  --tw-text-opacity: 1;
  color: rgb(97 97 97 / var(--tw-text-opacity));
}

.text-\[\#8692A6\]{
  --tw-text-opacity: 1;
  color: rgb(134 146 166 / var(--tw-text-opacity));
}

.text-\[\#8692a6\]{
  --tw-text-opacity: 1;
  color: rgb(134 146 166 / var(--tw-text-opacity));
}

.text-\[\#979797\]{
  --tw-text-opacity: 1;
  color: rgb(151 151 151 / var(--tw-text-opacity));
}

.text-\[\#ACB8CB\]{
  --tw-text-opacity: 1;
  color: rgb(172 184 203 / var(--tw-text-opacity));
}

.text-\[\#bababa\]{
  --tw-text-opacity: 1;
  color: rgb(186 186 186 / var(--tw-text-opacity));
}

.text-\[\#ffeb66\]{
  --tw-text-opacity: 1;
  color: rgb(255 235 102 / var(--tw-text-opacity));
}

.text-\[\#fff\]{
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.text-\[red\]{
  --tw-text-opacity: 1;
  color: rgb(255 0 0 / var(--tw-text-opacity));
}

.text-green-500{
  --tw-text-opacity: 1;
  color: rgb(34 197 94 / var(--tw-text-opacity));
}

.text-red-500{
  --tw-text-opacity: 1;
  color: rgb(239 68 68 / var(--tw-text-opacity));
}

.text-red-700{
  --tw-text-opacity: 1;
  color: rgb(185 28 28 / var(--tw-text-opacity));
}

.text-white{
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.underline{
  text-decoration-line: underline;
}

.outline{
  outline-style: solid;
}

.filter{
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.ease-in{
  transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Lato", sans-serif;
}

body {
  font-family: "Lato", sans-serif;
}

.login-app {
  background: url(./Assets/LoginBackground/backgroun.jpeg);
  background-size: cover;
  background-position: initial;
  background-size: auto;
  background-repeat: no-repeat;
  background-position: left;
  height: 100vh;
  width: 100vw;
  position: absolute;
  z-index: -999999999;
}

.login-app::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #19334EE5;
  opacity: 90%;
  z-index: -999999998;
}

::-webkit-scrollbar {
  width: 3px;
}

::-webkit-scrollbar-thumb {
  background-color: #555;
  border-radius: 4px;
}

::-webkit-scrollbar-track {
  background-color: #eee;
  border-radius: 4px;
}

.profileDrawer .ant-drawer-body{
  padding: 0px !important;
}

.hover\:bg-\[\#0B66A5\]:hover{
  --tw-bg-opacity: 1;
  background-color: rgb(11 102 165 / var(--tw-bg-opacity));
}

.hover\:bg-\[\#1C2B45\]:hover{
  --tw-bg-opacity: 1;
  background-color: rgb(28 43 69 / var(--tw-bg-opacity));
}

.hover\:bg-\[\#C6DFFF66\]:hover{
  background-color: #C6DFFF66;
}

.hover\:bg-\[\#F0F2F6\]:hover{
  --tw-bg-opacity: 1;
  background-color: rgb(240 242 246 / var(--tw-bg-opacity));
}

.focus\:ring-0:focus{
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}