.ellipse {
    position: absolute;
    width: 50%;
    height: 100%;
    right: 0;
    background: #fff;
  }
  
  .ellipse::before {
    content: "";
    border-radius: 35%/100%;
    background-color: #fff;
    clip-path: ellipse(50% 100% at 50% 50%);
    width: 100%;
    position: absolute;
    z-index: -333333;
    height: 100%;
    left: -100px;
    border-top-left-radius: 5%;
    border-bottom-left-radius: 5%;
  }
  
  .login-input {
    width: 350px;
    padding: 15px;
  }
  
  .login-input-text {
    color: var(--caption, #616061);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .footer{
    box-shadow: 10px 4px 10px 4px rgba(0, 0, 0, 0.08);
    border-top: 1px solid #eee;
  }
  

  .footer button{
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 10px 20px;
  }
  
  .boxShadowGoogle{
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.08);
    border: none;
  }
  