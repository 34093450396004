
  
  .chat-bot .image-container {
    position: absolute;
    bottom: 50px;
    right: 50px;
    background-image: linear-gradient(90deg, #2731DE, #05B9FC);
    border-radius: 100px;
    z-index: 100000000000000000;
    display: flex;
    padding: 10px;
    cursor: pointer;
  }
  
  .chat-bot .image-container {
    width: 60px;
  }
  
  .chat-bot .input-send-message {
    width: 100%;
    border-top: 1px solid #ddd;
    border: 0;
      outline: 0;
    border-bottom: 2px solid transparent;
    font-size: 16px;
    border: none;
    width: 80%;
  }
  
  .chat-bot .input-send-message:focus {
    border-color: #3047ec;
  }
  
 .chatContainer {
    /* position: absolute; */

    height: 80vh;
    width: 100%;
    border-radius: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .chat-with-us{
    position: absolute;
    bottom: 50px;
    right: 130px;
    cursor: pointer;
    box-shadow: #0000001A 0px 0px 10px;
    padding: 23px;
    border-radius: 30px;
  }
  
  
   .header {
    background-color: #fff;
    background-image: linear-gradient(90deg, #2731DE, #05B9FC);
    clip-path: polygon( 33.089% 98.78%,33.089% 98.78%,27.533% 99.485%,22.662% 99.664%,18.405% 99.394%,14.695% 98.749%,11.46% 97.808%,8.632% 96.645%,6.142% 95.337%,3.919% 93.961%,1.895% 92.592%,0% 91.307%,0% 7.805%,0% 7.805%,0.034% 6.539%,0.133% 5.338%,0.29% 4.218%,0.502% 3.195%,0.762% 2.286%,1.065% 1.506%,1.406% 0.871%,1.779% 0.398%,2.18% 0.102%,2.602% 0%,97.398% 0%,97.398% 0%,97.82% 0.102%,98.221% 0.398%,98.594% 0.871%,98.935% 1.506%,99.238% 2.286%,99.498% 3.195%,99.71% 4.218%,99.867% 5.338%,99.966% 6.539%,100% 7.805%,100% 92.768%,100% 92.768%,92.729% 88.448%,85.479% 86.138%,78.29% 85.495%,71.204% 86.175%,64.259% 87.833%,57.498% 90.127%,50.96% 92.713%,44.686% 95.246%,38.715% 97.383%,33.089% 98.78% );


    /* linear-gradient(115deg, rgb(64, 29, 186), rgb(130, 91, 240)); */
    /* linear-gradient(115deg, rgb(255, 78, 111), rgb(251, 145, 104)); */
    /* linear-gradient(115deg, rgb(172, 224, 249), rgb(255, 241, 235)); */
    /* background: linear-gradient(115deg, rgb(41, 50, 60), rgb(72, 85, 99)); */
    background-repeat: no-repeat;
    color: #fff;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    gap: 10px;
    padding: 10px;
    padding-bottom: 40px;
    display: flex;
    align-items: center;
  }
  
  .chat-bot .form-for-info{
    padding: 20px;
    height: 100%;
  }
  
  
  .chat-bot ::-webkit-scrollbar {
    width: 5px; /* Set the width of the scrollbar */
  }
  
  .chat-bot ::-webkit-scrollbar-track {
    background-color: transparent; /* Set the color of the scrollbar track */
  }
  
  .chat-bot ::-webkit-scrollbar-thumb {
    background-color: #ccc; /* Set the color of the scrollbar thumb */
    border-radius: 3px; /* Optional: Add rounded corners to the scrollbar thumb */
  }
  
  @media screen and (max-width: 768px) {
     .chatContainer {
      width: 100%;
      height: 100%;
      bottom: 0;
      right: 0;
      border-radius: 0;
    }
  
    .chat-bot .chat-container .header{
      background-size: auto;
    }
  }
  
  