@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;0,1000;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900;1,1000&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Lato", sans-serif;
}

body {
  font-family: "Lato", sans-serif;
}

.login-app {
  background: url(./Assets/LoginBackground/backgroun.jpeg);
  background-size: cover;
  background-position: initial;
  background-size: auto;
  background-repeat: no-repeat;
  background-position: left;
  height: 100vh;
  width: 100vw;
  position: absolute;
  z-index: -999999999;
}

.login-app::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #19334EE5;
  opacity: 90%;
  z-index: -999999998;
}


::-webkit-scrollbar {
  width: 3px; 
}

::-webkit-scrollbar-thumb {
  background-color: #555; 
  border-radius: 4px; 
}

::-webkit-scrollbar-track {
  background-color: #eee;
  border-radius: 4px; 
}

.profileDrawer .ant-drawer-body{
  padding: 0px !important;
}

.fc-header-toolbar
{
  position: relative !important;
} 

.fc-toolbar-chunk:nth-child(2){
  position: absolute;
  left: 44%;
  right: 50%;
}

.url-color-sender a{
   color: #fff; 
   text-decoration: underline;
}

.url-color-request a{
  color: #000;
  text-decoration: underline;
}



.ul-sender ul{
  list-style-type: disc !important; 
  padding:0 20px;
}

.ul-request ul{
  list-style-type: disc !important; 
  padding:0 20px;
} 


.classForFilter:hover{
  color: #000 !important;
}

.classForFilter{
  color: #fff !important;
}

.ant-input-disabled{
  color: #000 !important;
}